import { createRouter, createWebHistory } from 'vue-router';

import Dashboard from '../views/Dashboard/dashboard/Summary.vue';
import Index from '../views/App/Index.vue';
import store from '../store';
import authStore from '../stores/authStore';
import useAuthStore from "@/stores/authStore";


const routes = [
    //dashboard
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta:{ layout : 'app'}
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    //parcels
    {
        path: '/parcels/new',
        name: 'NewParcel',
        component: () => import('../views/Dashboard/parcels/AddParcel.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/parcels/all',
        name: 'AllParcels',
        component: () => import('../views/Dashboard/parcels/AllParcels.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/parcel/:id/edit',
        name: 'EditParcel',
        component: () => import('../views/Dashboard/parcels/EditParcel.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/parcels/:id',
        name: 'ViewParcel',
        component: () => import('../views/Dashboard/parcels/ViewParcel.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/parcel-history',
        name: 'parcel-history',
        component: () => import('../views/Dashboard/parcels/ParcelHistory.vue'),
        meta: { requiresAuth: true }

    },
    //Managers
    {
        path: '/users/managers/register-manager',
        name: 'RegisterManager',
        component: () => import('../views/Dashboard/managers/RegisterManagerView.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/users/managers/all',
        name: 'AllManagers',
        component: () => import('../views/Dashboard/managers/AllManagersView.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/users/managers/:id/edit',
        name: 'EditManager',
        component: () => import('../views/Dashboard/managers/EditManagerView.vue'),
        meta: { requiresAuth: true }

    },
    //Employees
    {
        path: '/users/employees/register-employee',
        name: 'RegisterEmployee',
        component: () => import('../views/Dashboard/employees/RegisterEmployeeView.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/users/employees/all',
        name: 'AllEmployees',
        component: () => import('../views/Dashboard/employees/AllEmployeesView.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/users/employees/:id/edit',
        name: 'EditEmployee',
        component: () => import('../views/Dashboard/employees/EditEmployeeView.vue'),
        meta: { requiresAuth: true }

    },
    //offices
    {
        path: '/offices/register-office',
        name: 'RegisterOffice',
        component: () => import('../views/Dashboard/offices/RegisterOfficeView'),
        meta: { requiresAuth: true }

    },
    {
        path: '/offices/all',
        name: 'AllOffices',
        component: () => import('../views/Dashboard/offices/AllOfficesView'),
        meta: { requiresAuth: true }
    },
    {
        path: '/offices/:id/edit',
        name: 'EditOffice',
        component: () => import('../views/Dashboard/offices/EditOfficeView.vue'),
        meta: { requiresAuth: true }
    },
    //branches
    {
        path: '/branches/register-branch',
        name: 'RegisterBranch',
        component: () => import('../views/Dashboard/branches/RegisterBranchView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/branches/all',
        name: 'AllBranches',
        component: () => import('../views/Dashboard/branches/AllBranchesView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/branches/:id/edit',
        name: 'EditBranch',
        component: () => import('../views/Dashboard/branches/EditBranchView.vue'),
        meta: { requiresAuth: true }
    },
    //categories
    {
        path: '/categories/register-category',
        name: 'AddCategory',
        component: () => import('../views/Dashboard/categories/RegisterCategoryView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/categories/all',
        name: 'AllCategories',
        component: () => import('../views/Dashboard/categories/AllCategoriesView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/categories/:id/edit',
        name: 'EditCategory',
        component: () => import('../views/Dashboard/categories/EditCategoryView.vue'),
        meta: { requiresAuth: true }
    },
    //vehicles
    {
        path: '/vehicles/register-vehicle',
        name: 'AddVehicle',
        component: () => import('../views/Dashboard/vehicles/RegisterVehicleView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/vehicles/all',
        name: 'AllVehicles',
        component: () => import('../views/Dashboard/vehicles/AllVehiclesView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/vehicles/:id/edit',
        name: 'EditVehicle',
        component: () => import('../views/Dashboard/vehicles/EditVehicleView.vue'),
        meta: { requiresAuth: true }
    },
    //admin auth
    {
        path:'/administrator/auth/login',
        name:'AdminLogin',
        component: () => import('../views/App/Auth/Administrator/LoginView.vue'),
        meta:{ layout : 'app'},

    },

    //Manager auth
    {
        path:'/manager/auth/login',
        name:'ManagerLogin',
        component: () => import('../views/App/Auth/Manager/LoginView.vue'),
        meta:{ layout : 'app'}
    },

    //employee auth
    {
        path:'/employee/auth/login',
        name:'EmployeeLogin',
        component: () => import('../views/App/Auth/Employee/LoginView.vue'),
        meta:{ layout : 'app'}
    },

    //all other routes
    {
        path: '/:catchAll(.*)',
        component:  () => import('../views/404.vue'),
        meta:{ layout : 'app'}
    }
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {

    if (to?.meta?.layout === 'app') {
        store.commit('setLayout', 'app');
    } else {
        store.commit('setLayout', 'dashboard');
    }

    next(true)

});

// global route guard
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const auth = useAuthStore();

    if (requiresAuth && !auth.$state.isAuthenticated) {
        next("/");
    } else {
        next();
    }
});

export default router;
