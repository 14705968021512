import axios from "axios";

export default async (endpoint, method, body) => {
    const authString = localStorage.getItem("authDetails") || "null";
    //parse the string to JSON
    const authObject = JSON.parse(authString);

    const authorization = authObject?.token || "";

    const headers = body instanceof FormData
        ? {authorization, Accept: "application/json"}
        : {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${authorization}`,
        };

    const baseURL = process.env.NODE_ENV === "development" ?
        "http://localhost:8000/v1/api" : "https://www.cinema.co.tz/v1/api";

    const response = await axios({
        method,
        url: `${baseURL}${endpoint}`,
        data: body,
        headers
    });


    const data = response.data;
    const status = response.status;

    return {data, status}
}