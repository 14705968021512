<template>
  <section id="values" class="values">

    <div class="container" data-aos="fade-up">

      <header class="section-header row d-flex justify-content-center">
        <p class="col-lg-8">Powering deliveries for motorcoach companies and logistics companies</p>
      </header>

      <div class="row">

        <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
          <div class="box">
            <img src="../../../assets/img/values-1.png" class="img-fluid" alt="">
            <h3>Free Custom Website</h3>
            <p>Obtain a complimentary custom website expertly designed with the latest technology to enhance
              and elevate your business, by using cutting edge technology to bring your business to the next level.</p>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
          <div class="box">
            <img src="../../../assets/img/values-2.png" class="img-fluid" alt="">
            <h3>Increase profitability</h3>
            <p> stay ahead of the competition by utilizing modern ways of managing your business. Utilizing
              POS devices and other advanced tools, you'll be able to attract more customers and boost your sales.
              With our cutting-edge solutions, you'll have the tools you need to stay ahead in today's fast-paced business environment.</p>
          </div>
        </div>

        <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
          <div class="box">
            <img src="../../../assets/img/values-3.png" class="img-fluid" alt="">
            <h3>Manage Staff, Agents and Offices.</h3>
            <p>Automatically generate a report on the sales made by each office, agent, and accurately determine the commission to be paid to them.</p>
          </div>
        </div>

      </div>

    </div>

  </section>
</template>

<script setup>

</script>

<style scoped>

</style>