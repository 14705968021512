<template>
  <div>
    <component :is="layout"></component>
    <NotificationToast />
  </div>
</template>

<script setup>
import { computed } from 'vue';

import '@/assets/sass/app.scss';
import NotificationToast from "./components/Shared/NotificationToast.vue";

// Vendor CSS Files
import '@/assets/vendor/aos/aos.css';
import '@/assets/css/styles.css';
import '@/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '@/assets/vendor/glightbox/css/glightbox.min.css';
import '@/assets/vendor/remixicon/remixicon.css';
import '@/assets/vendor/swiper/swiper-bundle.min.css';

import { useMeta } from '@/composables/use-meta';
import { useStore } from 'vuex';
useMeta({ title: 'Parcel Gala' });

const store = useStore();

const layout = computed(() => {
  return store.getters.layout;
});
</script>
<script>
// layouts
import AppLayout from './layouts/AppLayout.vue';
import DashboardLayout from './layouts/DashboardLayout.vue';

export default {
  components: {
    app: AppLayout,
    dashboard: DashboardLayout,
  },
};
</script>
