import { createStore } from 'vuex';

export default new createStore({
    state: {
        layout:'dashboard',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
    },
    actions: {},
    modules: {},
});
