<template>
  <div>
    <div>
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

