<template>
  <header style="background-color: #fff;" id="header" class="header fixed-top">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

      <div class="logo d-flex align-items-center">
        <img src="../../../assets/img/logo.png" alt="">
        <span>ParcelGala</span>
      </div>

      <nav id="navbar" class="navbar p-2" style="background-color: #fff;"
        :class="{ ' navbar-shadow-elevation navbar-mobile': isMobileNavOpen }">
        <ul>
          <li><a to="/" class="nav-link active" href="#hero">Home</a></li>
          <li><a to="/" class="nav-link" href="#about">About</a></li>
          <li><a to="/" class="nav-link" href="#blog">FAQS</a></li>
          <li><router-link class="nav-link" to="/">Contact Us</router-link></li>
          <li><router-link to="/employee/auth/login" class="nav-link">Staff Login</router-link></li>
          <li><router-link to="/manager/auth/login" class="nav-link">Manager Login</router-link></li>
          <li><router-link to="/administrator/auth/login" class="getstarted">Admin Login</router-link></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle" :class="{ 'bi-x': isMobileNavOpen }"
          v-on:click="isMobileNavOpen = !isMobileNavOpen"></i>
      </nav>

    </div>
  </header>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const isMobileNavOpen = ref(false)
</script>

<style scoped>
.navbar-mobile {
  background: rgba(1, 22, 61, 0.9) !important;
}
</style>
