<template>
  <div>

    <div v-if="loading" class="my-5 d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else class="layout-px-spacing dash_1">
      <div class="row layout-top-spacing">

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

          <!--        <h2 v-role="'employee'" class="d-flex justify-content-end" >{{ officeName }}</h2>-->
          <div class="row widget-statistic">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 layout-spacing ">
              <div class="widget main-background-color">
                <div class="widget-heading  py-4 ">
                  <div class="w-title">
                    <div class="w-icon icon-fill-primary p-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="feather feather-box">
                        <path
                            d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                        </path>
                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p class="text-size text-white">Total Collected Parcels</p>
                    <h5 class="text-white d-flex justify-content-end">{{ collectedParcels }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 layout-spacing ">
              <div class="widget" style="background-color: #bfc9d4;">
                <div class="widget-heading  py-4 ">
                  <div class="w-title">
                    <div class="w-icon icon-fill-primary p-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                           stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                    </div>
                  </div>
                  <div>
                    <p class="text-size text-white">Total Delivered</p>
                    <h5 class="text-white d-flex justify-content-end"> 0 </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 layout-spacing ">
              <div class="widget"  style="background-color: #009688;">
                <div class="widget-heading  py-4 ">
                  <div class="w-title">
                    <div class="w-icon icon-fill-primary p-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                           stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign">
                        <line x1="12" y1="1" x2="12" y2="23">

                        </line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    </div>
                  </div>
                  <div>
                    <p class="text-size text-white">Total cash collection</p>
                    <h5 class="text-white d-flex justify-content-end">{{ addThousandOperator(totalCashCollection) }} TZS</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 layout-spacing ">
              <div class="widget bg-secondary">
                <div class="widget-heading  py-4 ">
                  <div class="w-title">
                    <div class="w-icon icon-fill-primary p-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="feather feather-home">
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p class="text-size text-white">Total Offices</p>
                    <h5 class="text-white d-flex justify-content-end">{{ totalOffices }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 layout-spacing ">
              <div class="widget bg-dark">
                <div class="widget-heading  py-4 ">
                  <div class="w-title">
                    <div class="w-icon icon-fill-primary p-3">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p class="text-size text-white">Total Managers</p>
                    <h5 class="text-white d-flex justify-content-end">{{ totalManagers }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 layout-spacing ">
              <div class="widget bg-danger">
                <div class="widget-heading  py-4 ">
                  <div class="w-title">
                    <div class="w-icon icon-fill-primary p-3">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p class="text-size text-white">Total Staff</p>
                    <h5 class="text-white d-flex justify-content-end"> {{ totalEmployees }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script setup>
import '@/assets/sass/widgets/widgets.scss';
import { useStore } from 'vuex';
import ApexChart from 'vue3-apexcharts';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Dashboard' });
const store = useStore();

import { onMounted, ref,computed } from 'vue';
import useGeneralStore from "@/stores/generalStore";
import {storeToRefs} from "pinia/dist/pinia";
import OfficesTable from "@/components/layout/DashboardLayout/OfficesTable";
const {
  getAllManagersCount, getAllOfficesCount, getAllEmployeesCount,
  getCollectedParcelsCount, getUserOffice, getTotalCashCollection
} = useGeneralStore();

const {
  collectedParcels, officeName, totalOffices,
  totalManagers, totalEmployees, totalCashCollection
} = storeToRefs(useGeneralStore())


const loading = computed(() => collectedParcels.value === 0);

onMounted(async () => {
  // await getDeliveredParcelsCount();
  if(loading.value){
    await getAllEmployeesCount();
    await getAllManagersCount();
    await getAllOfficesCount();
    await getCollectedParcelsCount();
    await getTotalCashCollection();
    await getUserOffice();
  }
})

const addThousandOperator = (amount)=>{
  if (!amount) {
    return '';
  }

  const parts = amount.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
}

</script>

<style scoped>
.text-size{
  font-size: 17px;
}
</style>