<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div
        id="notificationToast"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
    >
      <div class="toast-header">
        <strong class="me-auto">
          <i v-if="!isSuccess" class="bi bi-exclamation-circle text-white me-2"></i>
          <i v-else class="bi bi-exclamation-circle text-white me-2"></i>
          {{header}}
        </strong>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
        ></button>
      </div>
      <div class="toast-body bg-white text-dark fw-bold">{{text}}</div>
    </div>
  </div>
</template>

<script setup>
import useNotificationStore from "@/stores/notificationStore.js";
import {storeToRefs} from "pinia"

const {showNotification, header, text, isSuccess} = storeToRefs(useNotificationStore());

</script>
