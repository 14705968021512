<template>
  <div v-if="loading" class="my-5 d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div v-else>

    <Header></Header>

    <div class="main-container" id="container"
        :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']">
      <!--  BEGIN OVERLAY  -->
      <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>
      <div class="search-overlay" :class="{ show: $store.state.is_show_search }" @click="$store.commit('toggleSearch', !$store.state.is_show_search)"></div>
      <!-- END OVERLAY -->

      <Sidebar></Sidebar>

      <div id="content" class="main-content">
        <router-view />
        <Footer></Footer>
      </div>

    </div>
  </div>
</template>

<script setup>
import Header from '@/components/layout/DashboardLayout/Header.vue';
import Sidebar from '@/components/layout/DashboardLayout/Sidebar.vue';
import Footer from '@/components/layout/DashboardLayout/Footer.vue';
import {onMounted, ref} from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import usePermissionStore from '@/stores/permissionStore';;
const { getAllPermissions } = usePermissionStore()
const { permissions } = storeToRefs(usePermissionStore());

const loading = ref(true);


onMounted(async () => {
//get user permissions
  await getAllPermissions();
  loading.value = false;

})
</script>

