<template>
  <section id="features" class="features">
    <div class="container" data-aos="fade-up">
      <header class="section-header">
        <p>All-in-One Courier Management Platform</p>
      </header>
  <!-- Feature Icons -->
  <div class="row feature-icons" data-aos="fade-up">
    <div class="row p-5">

      <div class="col-xl-12 m-4 d-flex content">
        <div class="row align-self-center gy-4">
          <div class="col-md-6 icon-box" data-aos="fade-up">
            <i class="ri-line-chart-line"></i>
            <div>
              <h4>Insightful Reports</h4>
              <p>
                Generate and download customizable reports on deliveries, revenue, expenses, profit, and more with our
                reporting feature. Easily analyze your data and gain insights into your operations.
              </p>
            </div>
          </div>

          <div
              class="col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="100"
          >
            <i class="ri-stack-line"></i>
            <div>
              <h4>Connect All Offices</h4>
              <p>
                Centralize the management of all your office locations, assign agents, and keep track of your inventory and
                deliveries with our office management feature.
              </p>
            </div>
          </div>

          <div
              class="col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="200"
          >
            <i class="ri-brush-4-line"></i>
            <div>
              <h4>Smart Dashboard</h4>
              <p>
                Track your daily, weekly, and monthly parcel deliveries, revenue, expenses, profit, and more
                with our rich and customizable dashboard. Easily filter data to gain insights into your operations
              </p>
            </div>
          </div>

          <div
              class="col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="300"
          >
            <i class="ri-user-line"></i>
            <div>
              <h4>Agent and Staff Management</h4>
              <p>
                Assign routes, vehicles and manage agents, track their performance, and ensure compliance with regulations.
              </p>
            </div>
          </div>

          <div
              class="col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="400"
          >
            <i class="ri-message-2-fill"></i>
            <div>
              <h4>Delight customers</h4>
              <p>
                Keep your customers informed and satisfied through automatic SMS notifications
              </p>
            </div>
          </div>

          <div
              class="col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="500"
          >
            <i class="ri-rocket-2-line"></i>
            <div>
              <h4>Grow</h4>
              <p>
                Scale your delivery business as much as you want hassle-free, and without the overhead cost.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="row m-4">-->
<!--      <div-->
<!--          class="col-12 text-center"-->
<!--          data-aos="fade-right"-->
<!--          data-aos-delay="100"-->
<!--      >-->
<!--        <img-->
<!--            src="../../../assets/img/gala.png"-->
<!--            class="img-fluid p-4"-->
<!--            alt=""-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <!-- End Feature Icons -->
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>

</style>