import { defineStore } from "pinia";
import apiClient from "@/apiClient";
const authString = localStorage.getItem("authDetails") || "null";
const authObject = JSON.parse(authString);

export default defineStore("auth", {
    state: () => ({
        isAuthenticated: authObject ? true : false,
        user: authObject?.user || null,
        token: authObject?.token || "",
    }),

    actions: {

        async adminLogin(loginDetails) {
            //send request to backend
            const { data, status } = await apiClient(
                "/auth/login/administrators",
                "post",
                JSON.stringify(loginDetails)
            );

            if (status !== 200) throw new Error(data.message);

            //update state
            this.isAuthenticated = true;
            this.token = data.token;

            this.user = {
                id: data.user.id,
                username: data.user.username,
                role: data.user.user_role,
                email: data.user.email? data.user.email : null ,
                status: data.user.status,
            };

            //save data to local storage
            const auth = {
                isAuthenticated: this.isAuthenticated,
                user: this.user,
                token: this.token,
            };

            localStorage.setItem("authDetails", JSON.stringify(auth));
            //return success true
            return true;
        },

        async managerLogin(loginDetails) {
            //send request to backend
            const { data, status } = await apiClient(
                "/auth/login/managers",
                "post",
                JSON.stringify(loginDetails)
            );

            if (status !== 200) throw new Error(data.message);

            //update state
            this.isAuthenticated = true;
            this.token = data.token;

            this.user = {
                id: data.user.id,
                username: data.user.username,
                role: data.user.user_role,
                email: data.user.email? data.user.email : null ,
                status: data.user.status,
            };

            //save data to local storage
            const auth = {
                isAuthenticated: this.isAuthenticated,
                user: this.user,
                token: this.token,
            };

            localStorage.setItem("authDetails", JSON.stringify(auth));
            //return success true
            return true;
        },

        async employeeLogin(loginDetails) {
            //send request to backend
            const { data, status } = await apiClient(
                "/auth/login/employees",
                "post",
                JSON.stringify(loginDetails)
            );

            if (status !== 200) throw new Error(data.message);

            //update state
            this.isAuthenticated = true;
            this.token = data.token;

            this.user = {
                id: data.user.id,
                username: data.user.username,
                role: data.user.user_role,
                email: data.user.email? data.user.email : null ,
                status: data.user.status,
            };

            //save data to local storage
            const auth = {
                isAuthenticated: this.isAuthenticated,
                user: this.user,
                token: this.token,
            };

            localStorage.setItem("authDetails", JSON.stringify(auth));
            //return success true
            return true;
        },

        logout() {
            //reset state
            this.isAuthenticated = false;
            this.user = null;
            this.token = ""

            //remove item form local storage
            localStorage.removeItem("authDetails");
        },
        async register(registerDetails) {
            //send request to backend
            const {data, status} = await apiClient(
                "/auth/register",
                "post",
                JSON.stringify(registerDetails)
            );

            if (status !== 200) throw new Error(data.message);

            //update state
            this.isAuthenticated = true;
            this.token = data.token;
            this.user = {
                id: data.user.id,
                username: data.user.username,
                role: data.user.user_role,
                email: data.user.email? data.user.email : null ,
                status: data.user.status,
            };

            //save data to local storage
            const auth = {
                isAuthenticated: this.isAuthenticated,
                user: this.user,
                token: this.token,
            };

            localStorage.setItem("authDetails", JSON.stringify(auth));

            //return success true
            return true;
        },
    },
});
