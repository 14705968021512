import { defineStore } from "pinia";

import apiClient from "@/apiClient";

export default defineStore("general", {
    state: () => ({
        collectedParcels: 0,
        deliveredParcels: 0,
        totalOffices: 0,
        totalManagers: 0,
        totalEmployees: 0,
        totalCashCollection: 0,
        officeName: '',
    }),

    actions: {
        // async getManagersAll() {
        //     const { data, status } = await apiClient("/managers", "get");
        //
        //     if (status !== 200) throw new Error(data.message);
        //
        //     this.allManagers = data.data;
        // },
        async getAllManagersCount() {
            const { data, status } = await apiClient("/total/managers", "get");

            if (status !== 200) throw new Error(data.message);

            this.totalManagers = data.data;
        },
        async getAllOfficesCount() {
            const { data, status } = await apiClient("/total/offices", "get");

            if (status !== 200) throw new Error(data.message);

            this.totalOffices = data.data;
        },
        async getAllEmployeesCount() {
            const { data, status } = await apiClient("/total/employees", "get");

            if (status !== 200) throw new Error(data.message);

            this.totalEmployees = data.data;
        },
        async getCollectedParcelsCount() {
            const { data, status } = await apiClient("/collected/parcels", "get");

            if (status !== 200) throw new Error(data.message);

            this.collectedParcels = data.data;
        },
        async getDeliveredParcelsCount() {
            const { data, status } = await apiClient("/delivered/parcels", "get");

            if (status !== 200) throw new Error(data.message);

            this.deliveredParcels = data.data;
        },
        async getTotalCashCollection() {
            const { data, status } = await apiClient("/total/cash-collection", "get");

            if (status !== 200) throw new Error(data.message);

            this.totalCashCollection = data.data;
        },

        async getUserOffice() {
            const { data, status } = await apiClient("/user/office-name", "get");

            if (status !== 200) throw new Error(data.message);

            this.officeName = data.data;
        },
    }
})