<template>
  <!-- BEGIN FOOTER -->
  <div class="footer-wrapper">
    <div class="footer-section f-section-1">
      <p class="">Copyright © 2023 <a target="_blank" href="https://arrangic.com">ExaBytes Africa</a>, All rights reserved.</p>
    </div>
    <div class="footer-section f-section-2">
      <p class="">
        Parcel Management System v.1.0
      </p>
    </div>
  </div>
  <!-- END FOOTER -->
</template>
