<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme" >
    <nav ref="menu" id="sidebar">

      <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{
        wheelSpeed: 0.5, swipeEasing: !0,
        minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true
      }">

        <!--        Dashboard-->
        <li class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#dashboard" aria-controls="dashboard"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-home">
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span>Dashboard</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="dashboard" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>

              <router-link to="/dashboard" @click="toggleMobileMenu">
                Summary
              </router-link>
            </li>
          </ul>
        </li>

        <!--        Parcels-->
        <li class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#components" aria-controls="components"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-box">
                <path
                  d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                </path>
                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                <line x1="12" y1="22.08" x2="12" y2="12"></line>
              </svg>
              <span> Manage Parcels </span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="components" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li  v-role="'employee'">
              <router-link to="/parcels/new" @click="toggleMobileMenu">New Parcel</router-link>
            </li>
            <li>
              <router-link to="/parcels/all" @click="toggleMobileMenu">All Parcels</router-link>
            </li>
            <!--            <li>-->
            <!--              <router-link to="/parcels/report" @click="toggleMobileMenu">Parcel Reports</router-link>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <router-link to="/parcels/track" @click="toggleMobileMenu">Track Parcel</router-link>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <router-link to="/parcels/invoice" @click="toggleMobileMenu">Invoice</router-link>-->
            <!--            </li>-->
          </ul>
        </li>

        <!--        branches-->
        <li  v-role="'administrator'" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#branches" aria-controls="branches"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-bookmark">
                <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
              </svg>
              <span> Manage Branches</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="branches" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link  to="/branches/register-branch" @click="toggleMobileMenu">New Branch</router-link>
            </li>
            <li>
              <router-link to="/branches/all" @click="toggleMobileMenu">All Branches</router-link>
            </li>
          </ul>
        </li>

        <!--        offices-->
        <li  v-role="'administrator'" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#datatables" aria-controls="datatables"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-layers">
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
              </svg>
              <span> Manage Offices</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="datatables" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/offices/register-office" @click="toggleMobileMenu">New Office</router-link>
            </li>
            <li>
              <router-link to="/offices/all" @click="toggleMobileMenu">All Offices</router-link>
            </li>
          </ul>
        </li>

        <!--    Managers-->
        <li  v-role="'administrator'" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#managers" aria-controls="managers"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span> Manage Managers </span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="managers" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/users/managers/register-manager" @click="toggleMobileMenu">New Manager</router-link>
            </li>
            <li>
              <router-link to="/users/managers/all" @click="toggleMobileMenu">All Managers</router-link>
            </li>
          </ul>
        </li>


        <!--        Employees-->
        <li  v-role="'manager'" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#users" aria-controls="users"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span> Manage Employees </span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="users" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/users/employees/register-employee" @click="toggleMobileMenu">New Employee</router-link>
            </li>
            <li>
              <router-link to="/users/employees/all" @click="toggleMobileMenu">All Employees</router-link>
            </li>
          </ul>
        </li>

        <!--   Categories-->
        <li  v-role="'administrator'" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#categories" aria-controls="categories"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-aperture">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
                <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
                <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
                <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
                <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
                <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line>
              </svg>
              <span> Manage Categories </span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="categories" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/categories/register-category" @click="toggleMobileMenu">New Category</router-link>
            </li>
            <li>
              <router-link to="/categories/all" @click="toggleMobileMenu">All Categories</router-link>
            </li>
          </ul>
        </li>

        <!--        Vehicles-->
        <li  v-role="'administrator'" class="menu">
          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#vehicles" aria-controls="vehicles"
            aria-expanded="false">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-truck">
                <rect x="1" y="3" width="15" height="13"></rect>
                <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                <circle cx="5.5" cy="18.5" r="2.5"></circle>
                <circle cx="18.5" cy="18.5" r="2.5"></circle>
              </svg>
              <span> Manage Vehicles </span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="vehicles" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/vehicles/register-vehicle" @click="toggleMobileMenu">New Vehicle</router-link>
            </li>
            <li>
              <router-link to="/vehicles/all" @click="toggleMobileMenu">All Vehicles</router-link>
            </li>
          </ul>
        </li>

        <!--        Reports-->
        <!--        <li class="menu">-->
        <!--          <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#pages" aria-controls="pages" aria-expanded="false">-->
        <!--            <div class="">-->
        <!--              <svg-->
        <!--                  xmlns="http://www.w3.org/2000/svg"-->
        <!--                  width="24"-->
        <!--                  height="24"-->
        <!--                  viewBox="0 0 24 24"-->
        <!--                  fill="none"-->
        <!--                  stroke="currentColor"-->
        <!--                  stroke-width="2"-->
        <!--                  stroke-linecap="round"-->
        <!--                  stroke-linejoin="round"-->
        <!--                  class="feather feather-file"-->
        <!--              >-->
        <!--                <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>-->
        <!--                <polyline points="13 2 13 9 20 9"></polyline>-->
        <!--              </svg>-->
        <!--              <span>Reports </span>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <svg-->
        <!--                  xmlns="http://www.w3.org/2000/svg"-->
        <!--                  width="24"-->
        <!--                  height="24"-->
        <!--                  viewBox="0 0 24 24"-->
        <!--                  fill="none"-->
        <!--                  stroke="currentColor"-->
        <!--                  stroke-width="2"-->
        <!--                  stroke-linecap="round"-->
        <!--                  stroke-linejoin="round"-->
        <!--                  class="feather feather-chevron-right"-->
        <!--              >-->
        <!--                <polyline points="9 18 15 12 9 6"></polyline>-->
        <!--              </svg>-->
        <!--            </div>-->
        <!--          </a>-->

        <!--          <ul id="pages" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">-->
        <!--            <li>-->
        <!--              <router-link to="/dashboard" @click="toggleMobileMenu">View Reports</router-link>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <router-link to="/dashboard" @click="toggleMobileMenu">Reports</router-link>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </li>-->

      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore();


const menu_collapse = ref('dashboard');

onMounted(async () => {
  const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
  if (selector) {
    const ul = selector.closest('ul.collapse');
    if (ul) {
      let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
      if (ele) {
        ele = ele[0];
        setTimeout(() => {
          ele.click();
        });
      }
    } else {
      selector.click();
    }
  }

});

const toggleMobileMenu = () => {
  if (window.innerWidth < 991) {
    store.commit('toggleSideBar', !store.state.is_show_sidebar);
  }
};


</script>
