<template>
  <section id="hero" class="hero d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up" >Transform Your Deliveries. Optimize Operations with our Advanced Courier Management System</h1>
          <h2 data-aos="fade-up" data-aos-delay="400" class="my-lg-4 lh-base" style="font-size: 18px !important;">
            Easily manage your fleet of vehicles, and deliveries. Track shipments, manage all your office locations and agents
            and improve customer service.
            Sign up now and take your delivery service to the next level.
          </h2>
          <div data-aos="fade-up" data-aos-delay="600">
            <div class="text-center text-lg-start">
              <router-link to="/auth/register" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Get Started</span>
                <i class="bi bi-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
          <img src="../../../assets/img/hero-img.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>

</script>

<style scoped>

</style>