import { defineStore } from "pinia";
import apiClient from "@/apiClient";

export default defineStore("permission", {
    state: () => ({
        permissions: [],
        role:'',
        bool:true
    }),

    actions: {
        async getAllPermissions() {
            const { data, status } = await apiClient("/user/permissions", "get");

            if (status !== 200) throw new Error(data.message);

            this.permissions = data.permissions;
            this.role = data.role
            localStorage.setItem('permissions', JSON.stringify(data.permissions))
            localStorage.setItem('role', JSON.stringify(data.role))
        },
    }
})

